@import 'ui/styles/utilities';

.container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  text-align: center;
  background: #fff;
  user-select: none;
  padding: var(--padding);
  flex-direction: column;

  @include md {
    background: var(--light);
    align-items: center;
  }
}

.content {
  width: 100%;
  background: #fff;
  border-radius: var(--border-radius);
  margin: auto;

  @include md {
    width: calc(100% - (var(--padding) * 2));
    max-width: 22em;
  }

  [class*='success'] {
    margin-top: var(--padding);
    margin-bottom: calc(var(--padding) / -2);
  }
}

.header {
  font-size: 1rem;
  margin: var(--padding) var(--padding) calc(var(--padding) / 4);
  padding-bottom: calc(var(--padding) / 1.5);
  border-bottom: 1px solid var(--dark05);

  & + div[class*='Banner'] > div {
    margin-top: 0;
  }
}

.intro {
  padding-bottom: calc(var(--padding) / 2);
  color: var(--dark50);
}

.input {
  border: 1px solid var(--dark10);
  display: block;
  margin-bottom: 1em;
  font-size: 0.9em;
  padding-bottom: 1em;
  padding: 0.85em 1em;
  border-radius: 0.35em;
  text-align: left;

  input {
    display: block;
    box-shadow: inset 0 2em 0 #fff;
    width: 100%;

    @include placeholder {
      color: var(--dark40);
    }
  }
}

.label {
  display: block;
  font-weight: 500;
  font-size: 0.7em;
  margin-bottom: 0.25em;
}

.inputs {
  padding: var(--padding);
  padding-top: calc(var(--padding) / 2);

  .button {
    font-size: 0.9em;
  }
}

.footer {
  font-size: 0.8rem;
  color: var(--dark50);
  text-align: center;
  margin: calc(var(--padding)) 0 0;
  font-weight: 500;

  a {
    color: var(--purple);
  }
}

.banner:not(:empty) {
  padding-bottom: 1em;

  > div {
    margin-top: 1em;
  }

  > div > div {
    margin-top: 0 !important;
  }
}

.separator {
  display: flex;
  align-items: center;
  margin: 1em 0;

  span {
    color: var(--dark30);
    font-size: 0.7em;
    font-weight: 500;
    margin: 0 0.5em;
    padding-bottom: 0.1em;
  }

  &::before,
  &::after {
    content: '';
    flex: 1;
    border: 0;
    border-top: 1px solid var(--dark05);
  }
}

.legal {
  margin-bottom: 0;
  display: flex;
  gap: 1rem;
  font-size: 0.7em;
  opacity: 0.5;
}
