.close {
  position: absolute;
  display: inline-block;
  opacity: 0.5;
  transition: opacity var(--speed) var(--ease);
  flex-shrink: 0;
  flex-grow: 0;
  padding-left: 1.8em;
  font-weight: 500;
  cursor: pointer;

  &:before,
  &:after {
    content: '';
    position: absolute;
    display: inline-block;
    left: 0;
    top: 50%;
    margin-top: -1px;
    width: 1.5em;
    height: 2px;
    background: currentColor;
    vertical-align: middle;
    transform: translate3d(0, 0, 0) scale(0.75) rotate(45deg);
    margin-left: 0.2em;
  }

  &:after {
    transform: translate3d(0, 0, 0) scale(0.75) rotate(-45deg);
  }

  &:hover {
    opacity: 0.6;
  }

  &:active {
    opacity: 1;
  }
}
