@import 'ui/styles/utilities';

.field {
  margin-top: calc(var(--padding) / -4);
  margin-bottom: calc(var(--padding) / 1.5);
  display: flex;
  align-items: center;
  width: 100%;
}

.label {
  width: 60%;
  padding-bottom: 0.1em !important;
}

.switch {
  font-size: 1.35em;
  display: block;

  &Container {
    width: 100%;
    height: 2.25em;
    display: flex;
    align-items: center;
  }

  &Input {
    visibility: hidden;
    width: 1px !important;
    position: absolute;
  }

  &Value {
    display: block;
    width: 1.85em;
    height: 1.185em;
    background: var(--dark10);
    border-radius: 2em;
    overflow: hidden;
    transform: rotate(0.1deg);
    transition: background-color var(--speed) var(--ease);
    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      top: 0.1em;
      left: 0.1em;
      width: 1em;
      height: 1em;
      background: #fff;
      box-shadow: 0 0.2em 0.4em var(--dark10);
      border-radius: 100%;
      transform: translate3d(0, 0, 0) rotate(0.1deg);
      transition: transform var(--speed) var(--ease);
    }
  }

  &Input:checked + &Value {
    background: var(--green);

    &:before {
      transform: translate3d(0.66em, 0, 0) rotate(0.1deg);
    }
  }
}
