@import 'ui/styles/utilities';

.field {
  width: 100%;
  // margin-bottom: calc(var(--padding)/1.5);

  input {
    width: 100%;
  }
}
