@import 'ui/styles/utilities';

.container {
  width: clamp(32em, 75%, 42em);
  padding: 1px calc(var(--padding) / 2) calc((var(--padding) / 2) + 1px);
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: calc(var(--padding) / 3.75);
}

.form {
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-gap: calc(var(--padding) / 4);
  font-size: var(--font-size);
  align-self: flex-start;
}

.warning {
  position: relative;

  [class*='_field'] [class*='_container'] {
    opacity: 0.1;
  }

  &:after {
    content: '';
    position: absolute;
    top: 3.3em;
    left: -1em;
    right: -1em;
    bottom: -1em;
    width: calc(100% + 2em);
    cursor: not-allowed;
  }
}
