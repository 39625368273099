.logo {
  display: block;
  font: 1rem var(--font-alt);

  i {
    font-family: 'CSIcons';
    margin-right: 0.25em;

    &:only-child {
      margin-right: 0;
    }

    &:before {
      content: 'E';
    }
  }

  em {
    position: relative;
    top: -0.4em;
    font-size: 0.75em;
    color: var(--purple);
  }
}
