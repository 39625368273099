@import 'ui/styles/utilities';

.field {
  width: calc(50% - (var(--padding) / 4));
  margin-bottom: calc(var(--padding) / 1.5);

  select {
    font-family: inherit;
    font-size: 1em;
    padding-left: 1.5em;
    padding: 0.5em 1em 0.5em 2.1em;
    width: 100%;
  }
}

.container {
  position: relative;
  border: 1px solid var(--dark05);
  border-radius: var(--border-radius);
  overflow: hidden;
  display: inline-flex;
  width: 100%;

  &:after,
  &:before {
    content: '';
    position: absolute;
    left: 1em;
    top: 50%;
    width: 0.3em;
    height: 0.3em;
    border-top: 1px solid var(--dark15);
    border-right: 1px solid var(--dark15);
    pointer-events: none;
  }

  &:before {
    transform: translate3d(0, 25%, 0) rotate(135deg);
  }

  &:after {
    transform: translate3d(0, -125%, 0) rotate(-45deg);
  }
}
