.container {
  font-size: var(--font-size);
  will-change: height, opacity;
  max-height: calc(5em + (var(--padding) * 0.75));
  display: flex;
  overflow: hidden;
  opacity: 1;
}

.bar {
  background: rgba(#ff8147, 0.15);
  text-align: center;
  border-radius: calc(var(--border-radius) / 1.5);
  font-weight: 500;
  padding: 1em;
  width: 100%;
  color: var(--orange);
  margin: calc(var(--padding) * 0.75) var(--padding) 0;
}

.warning .bar {
  background: rgba(#ff8147, 0.15);
  color: var(--orange);
}

.error .bar {
  background: rgba(#ff4747, 0.15);
  color: var(--red);
}

.success .bar {
  background: rgba(#74e691, 0.15);
  color: var(--green);
}

.notice .bar {
  background: var(--yellow);
  color: var(--dark70);
}

.fading {
  opacity: 0;
  transition:
    opacity 1s var(--ease),
    max-height var(--speed) var(--ease);
}

.hidden {
  max-height: 0;
  transition:
    opacity 1s var(--ease),
    max-height var(--speed) var(--ease);
}

.inline .bar {
  margin: 0 0 calc(var(--padding) * 0.75);
}
