@import 'ui/styles/utilities';

.field {
  position: relative;
  width: 100%;
}

.container {
  position: relative;
  display: flex;
  align-items: stretch;
  background: #fff;
  border: 1px solid var(--dark05);
  border-radius: var(--border-radius);
}

.field select {
  padding: 0.75em 0.9em;
  background: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.select {
  display: flex;
  position: relative;
  flex-grow: 1;

  &.hide {
    display: none;
  }

  &:not(:first-child) {
    border-left: 1px solid var(--dark05);
  }

  &:after,
  &:before {
    content: '';
    position: absolute;
    right: 1em;
    top: 50%;
    width: 0.3em;
    height: 0.3em;
    border-top: 1px solid var(--dark30);
    border-right: 1px solid var(--dark30);
    pointer-events: none;
  }

  &:before {
    transform: translate3d(0, 25%, 0) rotate(135deg);
  }

  &:after {
    transform: translate3d(0, -125%, 0) rotate(-45deg);
  }

  select {
    width: 100%;
    appearance: none;
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    border: 0;
  }
}

.copy {
  position: relative;
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  color: var(--dark);
  cursor: pointer;
  padding: 0.75em 0.9em;
  border-left: 1px solid var(--dark05);
  transition: color var(--speed) var(--ease);

  &:hover {
    color: var(--purple);
  }
}

.copy:after {
  content: attr(data-text);
  position: absolute;
  top: 50%;
  right: 100%;
  margin-right: 0.75em;
  background: var(--light);
  color: var(--dark);
  padding: 0.5em 1em;
  border-radius: 0.7em;
  transform: translate3d(1em, -50%, 0);
  opacity: 0;
  pointer-events: none;
  transition: all var(--ease) var(--speed);
}

.copied:after {
  opacity: 1;
  transform: translate3d(0, -50%, 0);
}

.disabled {
  cursor: not-allowed;

  div {
    pointer-events: none;
  }
}

.warning {
  font-size: 1.1em;
}
