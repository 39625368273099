@import 'ui/styles/utilities';

.field {
  width: 100%;
  margin-bottom: calc(var(--padding) / 1.5);
}

.intro {
  textarea {
    font-size: 1.25em;
    line-height: 1.3em;
  }
}
