.back {
  display: inline-block;
  opacity: 0.5;
  transition: opacity var(--speed) var(--ease);
  flex-shrink: 0;
  flex-grow: 0;
  font-weight: 500;

  &:before {
    content: '';
    position: relative;
    display: inline-block;
    width: 1em;
    height: 1em;
    border-top: 2px solid currentColor;
    border-left: 2px solid currentColor;
    vertical-align: middle;
    transform: translate3d(0, -0.15em, 0) scale(0.75) rotate(-45deg);
    margin-left: 0.2em;
  }

  &:hover {
    opacity: 0.6;
  }

  &:active {
    opacity: 1;
  }
}
