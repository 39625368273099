.field {
  margin-top: calc(var(--padding) / -4);
  margin-bottom: calc(var(--padding) / 1.5);
  display: flex;
  align-items: center;
  width: 100%;

  select {
    font-family: inherit;
    font-size: var(--font-size);
    padding-right: 1.5em;
    padding: 0.5em 1em;
    width: 100%;
    background: var(--dark05);
    font-weight: 500;
    color: var(--dark90);
    height: 2.25rem;

    option[disabled] {
      display: none;
    }
  }
}

.label {
  width: 60%;
  padding-bottom: 0.1em !important;
}

.wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.container {
  position: relative;
  border-radius: var(--border-radius);
  overflow: hidden;
  display: inline-flex;
  width: 100%;

  &:after,
  &:before {
    content: '';
    position: absolute;
    right: 0.75em;
    top: 50%;
    width: 0.3em;
    height: 0.3em;
    border-top: 1px solid currentColor;
    border-right: 1px solid currentColor;
    pointer-events: none;
    opacity: 0.2;
  }

  &:before {
    transform: translate3d(0, 25%, 0) rotate(135deg);
  }

  &:after {
    transform: translate3d(0, -125%, 0) rotate(-45deg);
  }
}

.typeface {
  width: calc(100% - 4.35rem);
  flex-shrink: 0;
  transition: all var(--speed) var(--ease);
}

.legacy .typeface {
  width: 100%;
}

.weight {
  width: 4rem;
  overflow: hidden;
  flex-shrink: 1;
  transition: all var(--speed) var(--ease);

  select {
    width: 4rem;
  }
}

.legacy .weight {
  width: 0;
  opacity: 0;
}
