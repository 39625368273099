@import 'ui/styles/utilities';

.container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  background: var(--dark10);
  backdrop-filter: blur(10px);
  padding: var(--padding);
}

.contents {
  position: relative;
  background: var(--light);
  border-radius: calc(var(--border-radius) * 1.65);
  overflow: hidden;
  height: 100%;
  font-size: var(--font-size);
  transform: translate3d(0, 0, 0);

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to right,
      rgba(#fff, 1) 80%,
      rgba(#fff, 0.95) 100%
    );
    background: linear-gradient(
      to right,
      rgba(#fff, 1) 50%,
      rgba(#fff, 0.6) 100%
    );
  }
}

.wrapper {
  height: 100%;
  width: 100%;
  margin: 0 auto;
}

.preview {
  position: fixed;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  transition: opacity calc(var(--speed) * 2) var(--ease);

  &Inside {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    padding-top: 5em;
    font-size: 1.3em;
  }

  &Desktop {
    position: absolute;
    top: 50%;
    left: 13vw;
    transform: translate3d(0, -50%, 0);
    box-shadow:
      0 0 0.5em var(--dark10),
      0 0.5em 3em var(--dark20);
    width: 55em;
    font-size: 0.8vw;
    background: #fff;
    border-radius: 1em;

    &Screen {
      @include ratio-box(1440/1340);
      position: relative;
    }
  }

  &Phone {
    background: url('./images/iphone.png') center / 100% auto no-repeat;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate3d(0, -50%, 0);
    filter: drop-shadow(0 0 0.5em var(--dark20))
      drop-shadow(0 0.5em 3em var(--dark40));
    width: 37.5em;
    font-size: 0.475vw;
    padding: 2em 2.2em 2.1em 2.2em;

    &Screen {
      @include ratio-box(375/812);
      position: relative;
    }
  }

  &PhoneScreen &Inside {
    font-size: 1.75em;

    section {
      padding: 1em !important;
    }

    [class*='_logo'] {
      margin-bottom: 1em;
      max-width: 6.5em;
      max-height: 6.5em;
    }
  }
}

.form {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 var(--padding) var(--padding);

  @media (min-width: 1024px) {
    width: 50%;
  }
}

.content {
  margin: auto;
  width: 100%;
  color: var(--dark);
  flex-grow: 0;
  min-height: 45%;
  margin-top: auto;
  margin-bottom: 0;
}

.header {
  position: sticky;
  top: 0;
  height: var(--nav-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
}

.footer {
  // border-top: 1px solid rgba($dark, .1);
  padding-top: var(--padding);
  // font-size: var(--font-size);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
}

.title {
  font-weight: bold;
  font-size: 3em;
  margin-bottom: 0.25em;
}

.label {
  margin-bottom: 0.5em;
  max-width: 22em;
  font-weight: 500;
  color: var(--dark50);
}

.title + .label {
  font-weight: normal;
}

.input {
  font-family: inherit;
  font-size: inherit;
  appearance: none;
  outline: none;
  border: 0;
  // margin-left: -.025em;
  width: calc(100%);
  background: transparent;
  padding: 0;
  color: var(--dark);
  box-shadow: inset 0 5rem 0 #fff;
  padding: 0;

  @include placeholder {
    color: var(--dark20);
  }
}

input.input {
  font-size: 2.5em;
}

textarea.input {
  color: inherit;
  font-size: 1.1rem;
  line-height: 1.5em;
  resize: none !important;
}

.pointer {
  font-size: 0.9em;
  color: var(--dark20);
  border-top: 1px solid var(--dark10);
  margin: 1em 0 0;
  padding: 0.5em 0;
}

.colors {
  padding: 1em 0 0.5em;

  > div:first-child {
    display: inline-block !important;
    width: auto !important;
    margin-bottom: 10px;
    margin-right: -3px !important;
    vertical-align: middle;
  }

  > div:first-child > span {
    display: inline-block;
  }

  > div:first-child > span div {
    display: inline-block;
  }

  &Selector {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    top: 3px;
    // margin: -32px 0 0 10px;

    &Popup {
      position: absolute;
      bottom: 0;
      left: 0;
      box-shadow:
        0 8px 10px -10px #00000060,
        0 0 4px #00000015;
      padding: 0.5em;
      background: #fff;
      border-radius: 0.8em;

      &Input {
        width: 100%;
        border: 0;
        font-family: inherit;
        font-size: 0.85rem;
        text-transform: uppercase;
        background: var(--dark05);
        padding: 0.5em;
        margin-top: 0.5em;
        border-radius: 0.5em;
      }

      &Button {
        font-size: 0.85rem;
        padding-top: 0.5rem;

        a {
          padding: 0.35em 0.5em;
          border: 0;

          &:after {
            display: none;
          }
        }
      }
    }

    .button {
      display: block;
      text-align: center;
    }

    > span {
      position: relative;
      display: inline-block;
      width: 50px;
      height: 50px;
      background: var(--dark20);
      border-radius: 100%;
      // border: 1px solid var(--dark20);
      border: 5px solid #fff;
      cursor: pointer;

      &:before,
      &:after {
        content: '';
        width: 2px;
        height: 1em;
        background: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
      }

      &:before {
        transform: translate3d(-50%, -50%, 0) rotate(0deg);
      }

      &:after {
        transform: translate3d(-50%, -50%, 0) rotate(90deg);
      }
    }
  }
}

@keyframes loaderPosition {
  #{ percentage(1 / 6 * 0) } {
    transform: translate3d(0, #{percentage(((-1/6) * 0) + 0.5)}, 0);
  }

  #{ percentage(1 / 6 * 1) } {
    transform: translate3d(0, #{percentage(((-1/6) * 1) + 0.5)}, 0);
  }

  #{ percentage(1 / 6 * 2) } {
    transform: translate3d(0, #{percentage(((-1/6) * 2) + 0.5)}, 0);
  }

  #{ percentage(1 / 6 * 3) } {
    transform: translate3d(0, #{percentage(((-1/6) * 3) + 0.5)}, 0);
  }

  #{ percentage(1 / 6 * 4) } {
    transform: translate3d(0, #{percentage(((-1/6) * 4) + 0.5)}, 0);
  }

  #{ percentage(1 / 6 * 5) } {
    transform: translate3d(0, #{percentage(((-1/6) * 5) + 0.5)}, 0);
  }

  #{ percentage(1 / 6 * 6) } {
    transform: translate3d(0, #{percentage(((-1/6) * 6) + 0.5)}, 0);
  }
}

@keyframes loaderShow {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.loader {
  font-size: 1.25em;

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    background: #fff;
    z-index: 99;
    width: 50%;
  }

  &:before {
    top: 0;
    height: 4em;
  }

  &:after {
    bottom: 0;
    height: 8em;
  }

  &Container {
    position: relative;
    opacity: 0.2;
    font-size: 2em;
    pointer-events: none;
    margin-top: -1em;

    &:after {
      content: '';
      position: absolute;
      bottom: 60%;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, #fff 20%, rgba(#fff, 0));
    }

    ul {
      display: block;
      animation: loaderPosition 8s ease forwards;

      li {
        opacity: 0;

        @for $i from 1 through 6 {
          &:nth-child(#{$i}) {
            animation: loaderShow
              #{8s *
              0.166667}
              #{(8s * 0.166667) *
              $i -
              1}
              ease
              forwards;
          }
        }
      }
    }
  }
}

.logos {
  display: grid;
  flex-wrap: wrap;
  // margin-top: calc(var(--padding)/-3);
  grid-template-columns: repeat(3, auto);
  grid-gap: calc(var(--padding) / 3);

  &Logo {
    @include ratio-box(2/1.125);
    display: block;
    position: relative;
    // width: 100%;
    // margin-right: calc(var(--padding)/3);
    overflow: visible;
    cursor: pointer;
    color: #000;

    &Img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(
          45deg,
          var(--dark05) 25%,
          transparent 25%,
          transparent 75%,
          var(--dark05) 75%,
          var(--dark05)
        ),
        linear-gradient(
          45deg,
          var(--dark05) 25%,
          transparent 25%,
          transparent 75%,
          var(--dark05) 75%,
          var(--dark05)
        );
      background-size: 20px 20px;
      background-position:
        0 0,
        10px 10px;
      box-shadow: 0 0 0 1px var(--dark10);
      border-radius: 0.75em;
      overflow: hidden;
      transition: box-shadow var(--speed) var(--ease);

      img,
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        max-width: 80%;
        max-height: 80%;
        transform: translate(-50%, -50%);
      }
    }

    .logosLogoInput {
      position: absolute;
      top: 0;
      left: 0;
      visibility: hidden;
    }

    .logosLogoInput:checked + .logosLogoImg {
      box-shadow: 0 0 0 3px var(--purple);
    }

    &Type {
      position: absolute;
      bottom: 0.6em;
      left: 0.65em;
      padding: 0.3em 0.7em;
      background: var(--dark30);
      font-size: 0.5em;
      text-transform: uppercase;
      color: #fff;
      font-weight: 500;
      border-radius: 2em;
      backdrop-filter: blur(10px);
      letter-spacing: 0.05em;
    }
  }
}

.upload {
  font-size: 1rem;
  margin-top: 1rem;

  [class*='Upload_image'] {
    display: none;
  }
}

.select {
  margin: 1em -0.5em;

  &Item {
    position: relative;
    cursor: pointer;

    &Input {
      position: absolute;
      bottom: 0;
      left: 0;
      visibility: hidden;
    }

    &Button {
      display: inline-block;
      color: var(--purple);
      background: var(--purple);
      font-size: 1.4em;
      padding: 0.5em 0.9em;
      border-radius: 2em;
      margin-right: 0.5em;
    }

    &Input:checked + &Button {
      background: var(--purple);
      color: #fff;
    }
  }
}
