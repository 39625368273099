.container {
  --navigation-height: 2rem;
  position: sticky;
  top: 0;
  z-index: 99;
  padding: var(--padding);

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: rgba(#fff, 0.85);
    backdrop-filter: blur(1vw);
    z-index: -1;
    mask: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 75%,
      rgba(0, 0, 0, 0) 100%
    );
  }
}

.wrapper {
  position: relative;
  height: var(--navigation-height);
  // border-bottom: 1px solid var(--dark05);
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--navigation-height);
  display: flex;
  justify-content: space-between;
  transition:
    translate var(--speed) var(--ease),
    opacity var(--speed) linear;
  font-size: var(--font-size);
  gap: 0.3rem;
}

.content--brand {
  translate: 0 100%;
  opacity: 0;
  pointer-events: none;
}

.container--brand .content--brand {
  translate: 0 0;
  opacity: 1;
  pointer-events: all;
}

.container--brand .content--overview {
  translate: 0 -100%;
  opacity: 0;
  pointer-events: none;
}

.items {
  display: flex;
  gap: 0.3rem;
}

.item {
  height: var(--navigation-height);
  min-width: var(--navigation-height);
  border-radius: 0.5rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.8em;
  overflow: hidden;
  transition: opacity var(--speed) linear;
}

.item--logo {
  background: var(--light);
  width: var(--navigation-height);
}

.item--button {
  position: relative;
  border: 0;
  background: var(--white);
  border: 1px solid var(--dark05);
  color: var(--dark);
  font-weight: 500;
  transform: translate3d(0, 0, 0);
  rotate: 0.01deg;
  cursor: pointer;
  transition:
    background var(--speed) var(--ease),
    border-color var(--speed) var(--ease);

  &:hover {
    background: color-mix(in srgb, var(--dark) 3.75%, transparent);
  }

  span,
  svg {
    position: relative;
    transform: translate3d(0, 0, 0);
  }

  svg {
    width: 2.5em;
    height: auto;
    opacity: 0.35;

    &:first-child {
      margin-left: -0.68em;
    }

    path {
      fill: currentColor;
    }
  }

  &__primary {
    color: var(--purple);
    background: color-mix(in srgb, currentColor 10%, transparent);
    border: none;

    &:hover {
      background: color-mix(in srgb, currentColor 15%, transparent);
    }

    svg {
      opacity: 1;
    }
  }

  &__active {
    color: var(--dark);
    background: color-mix(in srgb, var(--dark) 3.75%, transparent);
    border: 1px solid color-mix(in srgb, var(--dark) 7.5%, transparent);

    svg {
      color: var(--purple);
      opacity: 1;
    }
  }

  &::before {
    background: var(--light);
  }
}

.item--avatar {
  position: relative;
  overflow: hidden;
  width: var(--navigation-height);

  span {
    position: static;

    &::before {
      content: '';
      border-radius: 0;
      position: absolute;
      inset: 0;
      background: currentColor;
      opacity: 0.35;
      transition: opacity var(--speed) linear;
      transform: translate3d(0, 0, 0);
    }
  }
}

.item-back {
  position: relative;
  width: 1.1rem;
  height: 1.1rem;
  background: var(--dark10);
  margin-left: -0.25em;
  border-radius: 0.15rem;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0.45em;
    height: 0.45em;
    border-bottom: 2px solid var(--white);
    border-left: 2px solid var(--white);
    rotate: 45deg;
    translate: -25% -50%;
  }
}

.item-logo {
  position: relative;
  z-index: 1;
  height: 100%;
  margin: 0 0.35em;
  mix-blend-mode: darken;

  &:empty {
    display: none;
  }

  img {
    height: 100%;
    width: auto;
    max-width: 2rem;
    display: inline-block;
    object-fit: contain;
  }
}

.container--settings .item:not(.item--logo) {
  opacity: 0;
  pointer-events: none;
}

.item--inactive {
  .item-back {
    display: none;
  }
}
