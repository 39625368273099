@import 'ui/styles/utilities';

.container {
  margin-bottom: calc(var(--padding) * 0.25);
  margin: 0 var(--padding) var(--padding);
}

.bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--light);
  padding: 0.2em 0.9em;
  border-radius: var(--border-radius);

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}

.label {
  font-size: var(--font-size);
  display: block;
  font-weight: 500;
}

.button {
  color: var(--purple);
  font-weight: 500;
  font-size: var(--font-size);
  padding-top: 0.1em;
  margin-bottom: -0.1em;

  > span {
    color: inherit;
    padding: 0.5em 0.55em;
    border-radius: calc(var(--border-radius) / 1.5);
  }
}

.warning .bar {
  background: rgba(#ff8147, 0.15);
  color: var(--orange);
}

.error .bar {
  background: rgba(#ff4747, 0.15);
  color: var(--red);
}

.success .bar {
  background: rgba(#74e691, 0.15);
  color: var(--green);
}

.notice .bar {
  background: var(--yellow);
  color: var(--dark70);
}
