@import 'ui/styles/utilities';

.field {
  width: 100%;

  &:not(:last-child) {
    margin-bottom: var(--padding);
  }

  input {
    width: 100%;

    &:first-of-type {
      font-weight: 500;
    }

    &:last-of-type {
      color: var(--purple);
    }
  }
}
