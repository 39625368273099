@import 'ui/styles/utilities';

.field {
  width: 100%;

  &:not(:last-child) {
    margin-bottom: calc(var(--padding) / 1.5);
  }

  textarea {
    transition: height var(--speed) var(--ease);
  }
}

.brand {
  textarea {
    // font-family: var(--extra-font-family) !important;
    font-weight: 500;
    font-size: 1.2em;
  }
}

.title {
  textarea {
    font-size: 2em;
    font-weight: bold;
    line-height: 1em !important;
  }
}

.heading {
  textarea {
    font-size: 2.2rem;
    font-weight: bold;
    line-height: 2rem !important;
  }
}

.serif textarea {
  font-family: var(--title-font-family) !important;
}

.eyebrow textarea {
  text-transform: uppercase;
  letter-spacing: 0.035em;
  font-weight: bold;
}

.serif.eyebrow textarea {
  margin-bottom: 0.005em;
}

.inline {
  position: relative;
  margin-top: calc(var(--padding) / -4);
  margin-bottom: calc(var(--padding) / 1.5);
  display: flex;
  align-items: center;
  width: 100%;
  height: 2.25rem;

  .label {
    width: 60%;
    padding-bottom: 0.1em !important;
  }

  input {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    background: var(--dark05);
    border-radius: var(--border-radius);
    height: 100%;
    list-style: none;
    font-weight: 500;
    font-size: var(--font-size);
    flex-shrink: 1;
    padding: 0 1em;
  }
}
