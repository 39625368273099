@import 'ui/styles/utilities';

.container {
  position: sticky;
  top: var(--navbar-height);
  height: calc(100vh - var(--navbar-height));
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--color, var(--dark));
    opacity: 0.05;
  }
}

.preview {
  position: relative;

  iframe {
    width: 100%;
    height: 100%;
  }
}

.wrapper {
  background: var(--white);
  border-radius: var(--border-radius);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.header {
  width: 100%;
  padding: 0.35em 1em;
  display: flex;
  align-items: center;
  justify-content: center;

  .title {
    font-size: 0.75em;
    font-weight: 500;
    max-width: 15em;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0.35em 0.6em;
    // background: var(--dark05);
    border-radius: calc(var(--border-radius) / 2);
    align-items: center;
  }

  i {
    position: relative;
    display: block;
    width: 0.6em;
    height: 0.75em;
    flex-shrink: 0;
    transform: scale(0.9);
    opacity: 0.5;
    font-size: 0.75em;

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 60%;
      background: currentColor;
      border-radius: 2px;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 65%;
      height: 100%;
      border-radius: 10px;
      border: 1.5px solid currentColor;
      transform: translateX(-50%);
    }
  }
}

.mobile {
  width: 375px;
  height: 667px;
}

.desktop {
  width: 1280px;
  height: 720px;
  flex-shrink: 0;

  .header {
    background: var(--dark05);
    margin: 3px 3px 0 3px;
    width: calc(100% - 6px);
    border-radius: calc(var(--border-radius) * 0.7)
      calc(var(--border-radius) * 0.7) 0 0;
  }
}

.toggle {
  font-size: var(--font-size);
  position: absolute;
  bottom: 1em;
}
