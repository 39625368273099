@import 'ui/styles/utilities';

.field {
  margin-top: calc(var(--padding) / -4);
  margin-bottom: calc(var(--padding) / 1.5);
  display: flex;
  align-items: center;
  width: 100%;
}

.label {
  width: 60%;
  padding-bottom: 0.1em !important;
}

.input {
  width: 100%;
  height: 2.25em;
  display: flex;
  align-items: center;

  input {
    padding: 0;
    outline: 0;

    &[type='range'] {
      appearance: none;
      width: 100%;
      height: 0.25em;
      background-color: var(--dark10);
      background-image: linear-gradient(var(--dark), var(--dark));
      background-size: 50% 100%;
      background-repeat: no-repeat;
      border-radius: 1em;
    }

    &[type='range']::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 1.4em;
      height: 1.4em;
      background: var(--white);
      border-radius: 50%;
      cursor: pointer;
      box-shadow:
        0 0.2em 0.4em var(--dark10),
        0 0 0.2em var(--dark10);
      transition: box-shadow var(--speed) var(--ease);

      &:active {
        box-shadow:
          0 0.25em 0.5em var(--dark15),
          0 0 0.3em var(--dark15);
      }
    }

    &[type='range']::-moz-range-thumb {
      border: none;
      width: 2em;
      height: 2em;
      background: var(--white);
      border-radius: 50%;
      cursor: pointer;
      box-shadow:
        0 0.2em 0.4em var(--dark10),
        0 0 0.2em var(--dark10);
      transition: box-shadow var(--speed) var(--ease);

      &:active {
        box-shadow:
          0 0.25em 0.5em var(--dark15),
          0 0 0.3em var(--dark15);
      }
    }

    &[type='range']::-ms-thumb {
      border: none;
      width: 2em;
      height: 2em;
      background: var(--white);
      border-radius: 50%;
      cursor: pointer;
      box-shadow:
        0 0.2em 0.4em var(--dark10),
        0 0 0.2em var(--dark10);
      transition: box-shadow var(--speed) var(--ease);

      &:active {
        box-shadow:
          0 0.25em 0.5em var(--dark15),
          0 0 0.3em var(--dark15);
      }
    }

    &[type='range']::-webkit-slider-runnable-track,
    &[type='range']::-moz-range-track,
    &[type='range']::-ms-track {
      -webkit-appearance: none;
      box-shadow: none;
      border: none;
      background: transparent;
    }
  }
}
