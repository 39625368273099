.button {
  position: relative;
  display: inline-block;
  padding: 0.7em 1em 0.75em;
  border: 1px solid var(--dark20);
  border-radius: 0.65em;
  color: var(--purple);
  transition: all var(--speed) var(--ease);
  overflow: hidden;
  font-weight: 500;
  background: transparent;
  font-family: inherit;
  font-size: inherit;
  appearance: none;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: currentColor;
    opacity: 0;
    transition: opacity var(--speed) var(--ease);
  }

  &:hover:after {
    opacity: 0.05;
  }

  span {
    display: inline-block;
    transition: opacity var(--speed) var(--ease);
  }

  > svg {
    position: relative;
    top: -0.1em;
    display: inline-block;
    height: 1.5em !important;
    width: 1.5em !important;
    margin: -0.5em 0;
    vertical-align: middle;
  }

  i {
    position: relative;
    top: -0.1em;
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: middle;
    margin: -1.05rem 0 -1rem -0.9rem;

    svg {
      path {
        fill: currentColor;
      }
    }
  }

  @keyframes rotate {
    0% {
      transform: translate3d(-50%, -50%, 0) rotate(0deg);
    }

    100% {
      transform: translate3d(-50%, -50%, 0) rotate(360deg);
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    height: 1.4em;
    width: 1.4em;
    border: 2px solid currentColor;
    border-bottom-color: transparent;
    border-top-color: transparent;
    border-radius: 50%;
    animation: rotate 0.8s linear infinite;
    transition: opacity var(--speed) var(--ease);
    opacity: 0;
  }
}

.button + .button {
  margin-left: 0.3rem;
}

.primary {
  background: var(--light);
  border: 0;
  color: var(--purple);

  &:hover:after {
    opacity: 0.05;
  }
}

.full {
  width: 100%;
}

.inline {
  padding: 0.8em 1.6em;
}

.blank {
  padding: 0.8em 0;
  border: 0;
  border-radius: 0;
  color: var(--dark);
  opacity: 0.5;
}

.disabled {
  filter: grayscale(0.7);
  cursor: not-allowed;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: 0.5;
  }

  &:hover {
    &:after {
      background: #fff;
      opacity: 0.5;
    }
  }
}

.loading {
  span {
    opacity: 0;
  }

  &:before {
    opacity: 0.5;
  }
}

.icon {
  padding: 0.9em 0.725em;

  i {
    margin-left: -0.5em;
    margin-right: -0.5em;
  }
}

.small {
  font-size: 0.75em;
  padding: 0.45em 1.1em 0.55em 0.9em;
  white-space: nowrap;

  i {
    position: relative;
    top: -0.05em;
    margin-right: -0.3em;
  }
}

.medium {
  padding: 0.65em 1.25em 0.75em;
}

.white {
  color: inherit;
  background: #fff;
  border: 0;

  &:after {
    background: currentColor;
  }

  &:hover {
    // background: currentColor;

    &:after {
      opacity: 0.1;
    }
  }
}

.transparent {
  border: 0;
  margin: 0 -0.725em;
}

.light {
  color: var(--purple);
  background: var(--light);
  border: transparent;
}

.dangerous {
  color: var(--red);
  background: lighten(#ff4747, 34%);
  border: transparent;
}

.provider {
  svg {
    height: 1.4em;
    margin: -0.1em 0 -0.3em;
    margin-right: 0.5em;
  }
}

.google {
  background-color: #f2f2f2;
  color: #1f1f1f;
}

.microsoft {
  background-color: #f2f2f2;
  color: #1f1f1f;
}
