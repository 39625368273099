@import 'ui/styles/utilities';

.field {
  width: 100%;
  position: relative;
  margin-bottom: calc(var(--padding) / 1.5);
  overflow: hidden;
}

.drop {
  position: relative;
  font-size: var(--font-size);
  display: flex;
  align-items: center;

  &:after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.35s ease;
    text-align: center;
    background: #fff;
    border: 2px dashed #e5e5e5;
    border-radius: calc(var(--border-radius) / 2);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #bcbcbc;
    box-shadow: 0 0 0 0.5em #fff;
  }

  &.dragging:after {
    opacity: 1;
  }
}

.empty .drop {
  @include ratio-box(2/1);

  &:after {
    opacity: 1;
  }
}

.image {
  position: relative;
  width: 100%;
  // border-radius: var(--border-radius);
  // box-shadow: inset 0 0 0 1px rgba(#000,0.075);
  overflow: hidden;

  @keyframes rotate {
    0% {
      transform: translate3d(-50%, -50%, 0) rotate(0deg);
    }

    100% {
      transform: translate3d(-50%, -50%, 0) rotate(360deg);
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    height: 1.4rem;
    width: 1.4rem;
    border: 2px solid var(--dark20);
    border-bottom-color: transparent;
    border-top-color: transparent;
    border-radius: 50%;
    animation: rotate 0.8s linear infinite;
    transition: opacity var(--speed) var(--ease);
    opacity: 0;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center;
    opacity: 1;
    transition: opacity var(--speed) var(--ease);
    // background: var(--light);
    border: 0;
    outline: 0;

    &[src=''] {
      content: url('data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==');
    }
  }
}

.loading {
  .image {
    background: var(--light);
    align-self: stretch;
    z-index: 2;
    border-radius: calc(var(--border-radius) / 2);

    &:after {
      opacity: 1;
    }

    img {
      opacity: 0;
    }
  }
}
