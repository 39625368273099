@import 'ui/styles/utilities';

.field {
  margin-top: calc(var(--padding) / -4);
  margin-bottom: calc(var(--padding) / 1.5);
  display: flex;
  align-items: center;
  width: 100%;
}

.label {
  width: 60%;
  padding-bottom: 0.1em !important;
}

.options {
  display: flex;
  width: 100%;
  background: var(--dark05);
  border-radius: var(--border-radius);
  padding: 2px;
  overflow: hidden;
  height: 2.25em;
}

.option {
  // position: relative;
  cursor: pointer;
  width: 100%;
  flex-shrink: 1;
  font-size: var(--font-size);
  display: flex;

  .optionInput {
    position: absolute;
    visibility: hidden;
  }

  .optionValue {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    color: var(--dark70);
    padding: 0 1.1em;
    transition: all var(--speed) var(--ease);
    width: 100%;
    text-align: center;
    border-radius: calc(var(--border-radius) - 0.01em);
  }

  .optionInput:checked + .optionValue {
    color: var(--dark);
    background: var(--white);
    z-index: 1;
    box-shadow: 0 0.2em 0.4em var(--dark10);
  }
}

.font {
  .serif .optionValue {
    font-family: var(--title-font-family);
  }
}

.align {
  .optionValue span {
    display: none;
  }

  .optionValue {
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      height: 20px;
      width: 2px;
      background: currentColor;
      border-radius: 2px;
      transform-origin: right;
      transform: translate3d(-50%, -50%, 0) scaleX(0.75);
    }

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 20px;
      height: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      transform: translate3d(-50%, -50%, 0);

      &:before,
      &:after {
        content: '';
        height: 7px;
        width: 100%;
        background: currentColor;
        opacity: 0.4;
        border-radius: 2px;
      }

      &:after {
        width: 75%;
      }
    }
  }

  .left .optionValue {
    &:before {
      margin-left: -12px;
    }

    i {
      align-items: flex-start;
    }
  }

  .right .optionValue {
    &:before {
      margin-left: 12px;
    }

    i {
      align-items: flex-end;
    }
  }
}

.position {
  .optionValue span {
    display: none;
  }

  .optionValue {
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 24px;
      height: 2px;
      background: currentColor;
      border-radius: 2px;
      transform-origin: top;
      transform: translate3d(-50%, -50%, 0) scaleY(0.75);
    }

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 20px;
      height: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      transform: translate3d(-50%, -50%, 0);

      &:before,
      &:after {
        content: '';
        width: 9px;
        height: 100%;
        background: currentColor;
        opacity: 0.4;
        border-radius: 2px;
      }

      &:after {
        height: 70%;
      }
    }
  }

  .top .optionValue {
    &:before {
      margin-top: -10px;
    }

    i {
      align-items: flex-start;
    }
  }

  .bottom .optionValue {
    &:before {
      margin-top: 10px;
    }

    i {
      align-items: flex-end;
    }
  }
}

.layout {
  .optionValue span {
    display: none;
  }

  .optionValue {
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      width: calc(20px - 5px);
      height: calc(20px - 5px);
      background: currentColor;
      border-radius: 2px;
      opacity: 0.4;
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      width: 20px;
      height: 20px;
      box-shadow: inset 0 0 0 1.5px currentColor;
      border-radius: 4px;
    }
  }

  .portrait .optionValue {
    &:before {
      height: calc(24px - 5px);
    }

    &:after {
      height: calc(24px);
    }
  }

  .landscape .optionValue {
    &:before {
      width: calc(24px - 5px);
    }

    &:after {
      width: calc(24px);
    }
  }
}
