@import 'ui/styles/utilities';

.field {
  width: 100%;
  margin-bottom: calc(var(--padding) / 1.5);
}

.option {
  position: relative;
  display: inline-block !important;
  cursor: pointer;
  opacity: 1 !important;
  font-size: 1em !important;

  &:not(:last-child) {
    margin-right: calc(var(--padding) / 4);
  }

  .optionInput {
    position: absolute;
    visibility: hidden;
  }

  .optionValue {
    display: inline-block;
    font-weight: 500;
    border: 2px solid currentColor;
    color: var(--dark30);
    font-size: 0.9em;
    padding: 1.4em 1.5em;
    border-radius: var(--border-radius);
    transition: color var(--speed) var(--ease);
  }

  .optionInput:checked + .optionValue {
    color: var(--purple);
  }
}
