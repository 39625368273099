@use 'sass:math';

@mixin ratio-box($ratio) {
  overflow: hidden;

  &:before {
    content: '';
    display: block;
    height: 0;
    padding-top: #{(math.div(1, $ratio) * 100%)};
  }
}
