@import 'ui/styles/utilities';

.field {
  width: 100%;
  margin-bottom: calc(var(--padding) / 1.5);
  color: var(--dark);
}

.drop {
  position: relative;
  font-size: var(--font-size);
  display: flex;
  align-items: center;
  overflow: hidden;

  &:after {
    content: attr(data-text);
    position: absolute;
    inset: 0;
    cursor: pointer;
    transition: opacity 0.35s ease;
    text-align: center;
    background: var(--white);
    border: dashed 1.5px var(--dark10);
    border-radius: calc(var(--border-radius) / 1.25);
    outline: 0.25em solid var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--dark20);
    font-weight: 500;
    opacity: 0;
    pointer-events: none;
  }

  &.dragging:after {
    opacity: 1;
  }
}

.image {
  position: relative;
  border-radius: calc(var(--border-radius) * 0.75);
  margin-right: 1rem;
  overflow: hidden;
  background: var(--dark025);

  @keyframes rotate {
    0% {
      transform: translate3d(-50%, -50%, 0) rotate(0deg);
    }

    100% {
      transform: translate3d(-50%, -50%, 0) rotate(360deg);
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    height: 1.4rem;
    width: 1.4rem;
    border: 2px solid var(--white);
    border-bottom-color: transparent;
    border-top-color: transparent;
    border-radius: 50%;
    animation: rotate 0.8s linear infinite;
    transition: opacity var(--speed) var(--ease);
    opacity: 0;
  }

  img,
  video {
    display: block;
    width: auto;
    height: auto;
    max-width: 6rem;
    max-height: 6rem;
    object-fit: cover;
    object-position: center;
    opacity: 1;
    transition: opacity var(--speed) var(--ease);
    border: 0;
    outline: 0;
  }

  img,
  video {
    &[src=''],
    &:not([src]) {
      content: url('data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==');
      opacity: 0;
    }
  }
}

.logo .image {
  box-shadow: inset 0 0 0 1px var(--dark05);
}

.logo .image img {
  width: 5rem;
  height: 4rem;
  object-fit: contain;
  margin: 0.5rem;
  background: transparent;
}

.transparent .image {
  background-color: var(--dark50);
  background-image: repeating-linear-gradient(
      45deg,
      var(--dark) 25%,
      transparent 25%,
      transparent 75%,
      var(--dark) 75%,
      var(--dark)
    ),
    repeating-linear-gradient(
      45deg,
      var(--dark) 25%,
      var(--dark50) 25%,
      var(--dark50) 75%,
      var(--dark) 75%,
      var(--dark)
    );
  background-position:
    0 0,
    0.5em 0.5em;
  background-size: 1em 1em;

  img {
    background: transparent;
  }
}

.cover {
  width: calc(50% - (var(--padding) / 4));
}

.cover .image {
  img,
  video {
    width: 4rem;
    height: 4rem * 1.3;
    max-height: 4rem * 1.3;
    margin: 0;
    object-fit: cover;
  }
}

.background .image {
  img,
  video {
    width: 6rem;
    height: 4.5rem;
    margin: 0;
    object-fit: cover;
  }
}

.loading {
  .image {
    &:after {
      opacity: 1;
    }
  }
}

.actions {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  transform: translate3d(0, 1.125em, 0);
  transition: transform var(--speed) var(--ease);
}

.files .actions {
  transform: translate3d(0, 0, 0);
}

.action {
  position: relative;
  line-height: 1em;
  width: auto;
  min-width: 2em;
  height: 2em;
  border-radius: 3em;
  padding: 0.5em 0.85em 0.4em 0.6em;
  transform: translate3d(0, 0, 0) rotate(0.01deg);
  overflow: hidden;
  cursor: pointer;
  color: var(--dark40);
  font-weight: 500;
  transition: opacity var(--speed) var(--ease);
  white-space: nowrap;

  &:not(:last-child) {
    margin-bottom: 0.25em;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: currentColor;
    opacity: 0.1;
    transition: opacity var(--speed) var(--ease);
  }

  &:hover:before {
    opacity: 0.2;
  }

  svg {
    height: 2em;
    margin: -0.5em -0.25em -0.4em -0.6em;
    transition: transform var(--speed) var(--ease);
    vertical-align: middle;

    path {
      fill: currentColor;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0) rotate(0.1deg);
      width: 0.1rem;
      height: 90%;
      background: currentColor;
      border-radius: 5em;
    }

    &:after {
      transform: translate3d(-50%, -50%, 0) rotate(90deg);
    }
  }

  &.danger {
    color: var(--red);
    opacity: 0;
    pointer-events: none;

    @at-root .files & {
      opacity: 1;
      pointer-events: all;
    }
  }
}
