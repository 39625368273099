@import 'ui/styles/utilities';

.field {
  position: relative;
  width: 100%;
}

.container {
  position: relative;
  display: flex;
  align-items: stretch;
  background: #fff;
  border: 1px solid var(--dark05);
  border-radius: var(--border-radius);
  overflow: hidden;
}

.field input {
  padding: 0.75em 0.9em;
  padding-right: 0;
  width: 100%;
}

.copy {
  position: relative;
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  color: var(--dark);
  cursor: pointer;
  padding: 0.75em 0.9em;
  border-left: 1px solid var(--dark05);
  transition: color var(--speed) var(--ease);

  &:hover {
    color: var(--purple);
  }
}

.copy:after {
  content: attr(data-text);
  position: absolute;
  top: 50%;
  right: 100%;
  margin-right: 0.75em;
  background: var(--light);
  color: var(--dark);
  padding: 0.5em 1em;
  border-radius: 0.7em;
  transform: translate3d(1em, -50%, 0);
  opacity: 0;
  pointer-events: none;
  transition: all var(--ease) var(--speed);
}

.copied:after {
  opacity: 1;
  transform: translate3d(0, -50%, 0);
}
