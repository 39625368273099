@import 'ui/styles/utilities';

.field {
  width: calc(50% - (var(--padding) / 4));

  &:not(:last-child) {
    margin-bottom: var(--padding);
  }
}

.switch {
  padding-top: 1rem;
  font-size: 1.35em;
  display: block;

  &Input {
    visibility: hidden;
    position: absolute;
  }

  &Value {
    display: block;
    width: 2.5em;
    height: 1.5em;
    background: var(--dark10);
    border-radius: 2em;
    overflow: hidden;
    transform: translateY(-50%);
    transform: translate3d(0, -50%, 0) rotate(0.1deg);
    transition: background-color var(--speed) var(--ease);
    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      top: 0.1em;
      left: 0.1em;
      width: 1.3em;
      height: 1.3em;
      background: #fff;
      box-shadow: 0 0.2em 0.4em var(--dark10);
      border-radius: 100%;
      transform: translate3d(0, 0, 0) rotate(0.1deg);
      transition: transform var(--speed) var(--ease);
    }
  }

  &Input:checked + &Value {
    background: var(--green);

    &:before {
      transform: translate3d(1em, 0, 0) rotate(0.1deg);
    }
  }
}
